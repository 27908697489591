.panel-brand1{
//panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border)
	@include panel-variant(transparent, #3e3f44, #bae2f4, transparent)
	> .panel-heading {
		padding: 9px 24px 8px;
    border-bottom: 1px solid transparent;
    border-radius: 5px;
	}
	&.panel-open{
		@include panel-variant(transparent, #fff, #2bace3, transparent)		
		>.panel-heading{			
	    border-top-right-radius: 5px;
	    border-top-left-radius: 5px;
	    border-bottom-right-radius: 0;
	    border-bottom-left-radius: 0;
		}
	}
	>.panel-body{
		box-shadow: 0.5px 0.9px 21px 0 rgba(0, 0, 2, 0.05);
	}
}