$grid-columns: 15;
$grid-columns-apl: 15;
$class-apl: 'apl';
$grid-gutter-width: 8px;
.row-apl{
  margin-left: -4px;
  margin-right: -4px;
}
@mixin make-grid-columns2($i: 1, $list: ".col-apl-#{$i}, .col-apl-#{$i}, .col-apl-#{$i}, .col-apl-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-apl-#{$i}, .col-apl-#{$i}, .col-apl-#{$i}, .col-apl-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
  }
}
@include make-grid-columns2;
@include float-grid-columns($class-apl);
@include loop-grid-columns($grid-columns-apl, $class-apl, width);
@include loop-grid-columns($grid-columns-apl, $class-apl, pull);
@include loop-grid-columns($grid-columns-apl, $class-apl, push);
@include loop-grid-columns($grid-columns-apl, $class-apl, offset);