.btn-default2{
  //button-variant($color, $background, $border)
  //button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  @include button-size(10px, 26px, 12px, 1, 5px);
  @include button-variant(#464750, #f7f8f8, #cdced4);
  font-weight: bold;
}

.btn-caret{
	width: 43px;
  height: 43px;
  border-radius: 21.5px;
  border: solid 1px #cdced4;
  background: #f6f8f9 url(../imgs/form/caret.png) center center no-repeat
}

.btn-brand1{
  @include button-size(10px, 55px, 12px, 1.67, 5px);
  @include button-variant(#fff, #ef5026, #ef5026);  
}
.btn-brand2{
  @include button-size(10px, 5px, 12px, 1.67, 5px);
  @include button-variant(#fff, #ef5026, #ef5026);
}

//btn btn-linkborder btn-width100
.btn-linkborder{  
  @include button-size(15px, 15px, 12px, 1.67, 5px);
  @include button-variant(#464750, #f7f8f8, #cdced4);
  font-weight: bold;
  line-height: 1.67;
  text-align: center;
  &:hover,&:focus{
    text-decoration: none;
  }
}
.btn-width100{
  width: 100%;
}

.btn-link2{
  @include button-size(6px, 12px, 12px, 1.67, 0);
  @include button-variant(#464750, transparent, transparent);
}
.btn-link3{
  @include button-size(6px, 0, 12px, 1.67, 0);
  @include button-variant(#464750, transparent, transparent);
  font-weight: bold;
}

.btn-bold{
  font-weight: bold;
}

.btn-primary-upload{
    //button-variant($color, $background, $border)
  //button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  @include button-size(10px, 26px, 12px, 1, 5px);
  @include button-variant(#fff, #2bace3, #2bace3);
  font-weight: bold;
}