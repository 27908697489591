// Default variables.
@import 'default-variables';

// Bootstrap Framework.
@import '../bootstrap/assets/stylesheets/bootstrap';

// Base-theme overrides.
@import 'overrides';
@import "buttons";
@import "panel";

/*Дополнительная сетка в 15 колонок, для 5 рядов и т.п.*/
@import "addgrid";